//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ref } from "@nuxtjs/composition-api";
import SimpleImage from "./SimpleImage.vue";
import VueSlickCarousel from "vue-slick-carousel";
import { Icon } from "@iconify/vue2";

export default {
  name: "BrandsSlider",
  props: ["blok"],
  components: {
    SimpleImage,
    VueSlickCarousel,
    Icon,
  },
  setup() {
    const settings = ref({
      infinite: true,
      arrows: true,
      dots: false,
      slidesToShow: 3,
      speed: 500,
      rows: 1,
      slidesPerRow: 1,
      draggable: false,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
          },
        },
      ],
    });

    return {
      settings,
    };
  },
};
