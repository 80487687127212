//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SimpleImage",
  props: {
    item: {
      type: Object,
      required: false,
      default: {},
    },
  },
};
